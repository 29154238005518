import * as React from "react"
import { Button, Stack, Select, A, Text } from "@framerjs/fraction"
import { ITeam, updateTeam } from "../../util/api"
import Intro from "../../components/intro"

interface IProps {
  team: ITeam | undefined
  onSkip: () => void
  onUpdatedTeam: () => void
  onError: (error: Error) => void
}

export default function RecommendTeam({ team, onSkip, onUpdatedTeam, onError }: IProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [defaultRole, setDefaultRole] = React.useState<"viewer" | "editor">("editor")
  const autoJoinDomains = (team?.domainConfigurations || [])
    .filter(({ isPrivateDomain, isAutoJoin }) => isPrivateDomain && isAutoJoin)
    .map(({ domain }) => domain)

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!team) {
        return
      }
      setIsLoading(true)
      updateTeam({ ...team, configuration: { autoJoinDefaultRole: defaultRole, autoJoinTeam: true } })
        .then(() => {
          onUpdatedTeam()
        })
        .catch((err) => {
          setIsLoading(false)
          onError(err)
        })
    },
    [defaultRole, onError, onUpdatedTeam, team]
  )

  const onChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    if (value !== "viewer" && value !== "editor") {
      return
    }
    setDefaultRole(value)
  }, [])

  const handleOnSkip = React.useCallback(() => {
    if (isLoading) return
    onSkip()
  }, [onSkip, isLoading])

  if (autoJoinDomains.length < 1) {
    onSkip()
    return null
  }

  return (
    <div>
      <Intro
        icon={null}
        heading="Recommend workspace"
        text={
          <>
            Recommend the workspace to any users <br /> joining with a {autoJoinDomains.join(", ")} domain
          </>
        }
      />
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="column" gap={15}>
          <Select
            onChange={onChangeHandler}
            background="rgba(255,255,255,0.1)"
            style={{
              backgroundImage:
                "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNTMwMzMgMC45Njk2N0M3LjgyMzIyIDEuMjYyNTYgNy44MjMyMiAxLjczNzQ0IDcuNTMwMzMgMi4wMzAzM0w0IDUuNTYwNjZMMC40Njk2NyAyLjAzMDMzQzAuMTc2Nzc3IDEuNzM3NDQgMC4xNzY3NzcgMS4yNjI1NiAwLjQ2OTY3IDAuOTY5NjdDMC43NjI1NjMgMC42NzY3NzcgMS4yMzc0NCAwLjY3Njc3NyAxLjUzMDMzIDAuOTY5NjdMNCAzLjQzOTM0TDYuNDY5NjcgMC45Njk2N0M2Ljc2MjU2IDAuNjc2Nzc3IDcuMjM3NDQgMC42NzY3NzcgNy41MzAzMyAwLjk2OTY3WiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K')",
            }}
          >
            <option key="editor" value={"editor"}>
              Default as Editor
            </option>
            <option key="viewer" value={"viewer"}>
              Default as Viewer
            </option>
          </Select>

          <Button loading={isLoading} fluid variant="primary" type="submit">
            Continue
          </Button>
          <Text size="small" align="center">
            <A style={{ color: "rgba(255,255,255,0.6)" }} onClick={handleOnSkip}>
              Skip this step
            </A>
          </Text>
        </Stack>
      </form>
    </div>
  )
}
